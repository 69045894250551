import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { servicesLinks } from 'components/Layout/navigation';
import Service from 'components/Service_SourcingFromChina';
import { ImgGatsbyBackground } from 'components/wrappers';
import Icon1 from 'images/crowdfunding-fulfillment/all-included-rate-icon.png';
import Icon2 from 'images/crowdfunding-fulfillment/full-cost-transparency-icon.png';
import Icon3 from 'images/crowdfunding-fulfillment/full-tracking-icon.png';
import Step1 from 'images/crowdfunding-fulfillment/steps/get-in-touch.png';
import Step2 from 'images/crowdfunding-fulfillment/steps/order-exchange.png';
import Step3 from 'images/crowdfunding-fulfillment/steps/pickup.png';
import Step4 from 'images/crowdfunding-fulfillment/steps/fulfillment.png';
import Step5 from 'images/crowdfunding-fulfillment/steps/delivery.png';
const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ShippingFromChinaPage {
      bg: file(relativePath: { eq: "services-crowdfunding-fulfillment.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.bg.childImageSharp.fluid} alt="shipkoo global ecommerce product fulfillment service" />;
  const IconCardOne = () => <img src={Icon1} className="service-icons" alt="Services Icon" />;
  const IconCardTwo = () => <img src={Icon2} className="service-icons" alt="Services Icon" />;
  const IconCardThree = () => <img src={Icon3} className="service-icons" alt="Services Icon" />;
  const intl = useIntl()
  return (
    <Service
      BackgroundImage={BackgroundImage}
      IconCardOne={IconCardOne}
      IconCardTwo={IconCardTwo}
      IconCardThree={IconCardThree}
      navigationTabs={servicesLinks}
      activeTab={servicesLinks[2].value}
      description={
        <>
          {intl.formatMessage({ id: "sourcing-from-china-header-description" })}
        </>
      }
      description2={
        <>
          {intl.formatMessage({ id: "sourcing-from-china-header-description2" })}
        </>
      }
      header={
        <>
          {intl.formatMessage({ id: "sourcing-from-china-sub-header-description" })}
        </>
      }
      about={intl.formatMessage({ id: "sourcing-from-china-header" })}
      cardsTitle={[
        'Platform Integrations',
        'Global Fulfillment',
        'Express Shipping',
      ]}
      cards={[
        'A robust fulfillment system, in which a one-click order syncs with 100+ marketplaces and platforms',
        'Fulfillment centers and bonded facilities in China, the UK, the US, Japan, Korea, Poland and Vietnam  means that you’re always close to a Shipkoo hub',
        'On-demand service at fast speeds: Shipkoo sends out deliveries within 24 hours of the order being placed',
      ]}
      numSteps={intl.formatMessage({ id: "sourcing-from-china-steps-title" })}
      timelineItems={[
        {
          titleStep: 'Step 1',
          title: 'Get in Touch',
          description:'Drop us a line and we will get in touch as soon as possible',
          image: Step1,
          alt: 'shipkoo ecommerce order shipping fulfillment service',
          titleStepZH: '第一步',
          titleZH: '取得联系',
          descriptionZH: '留下你的联系方式，我们会尽快与你联系',
          titleStepKO: '1단계',
          titleKO: '연락이 닿다',
          descriptionKO: '연락처를 남겨두면 빠른 시일 내에 연락드리겠습니다',
          titleStepJP: 'ステップ1',
          titleJP: '取得联系',
          descriptionJP: 'あなたの連絡先情報を残してください、私たちはできるだけ早くあなたに連絡します',
        },
        {
          titleStep: 'Step 2',
          title: 'Order Exchange',
          description:'Based on your complete order list we will determine the fulfillment and shipping costs',
          image: Step2,
          alt: 'shipkoo best ecommerce fulfillment service thorough product inspection and stocking',
          titleStepZH: '第二步',
          titleZH: '订单处理',
          descriptionZH: '基于你完成的订单我们会确定运输方式和费用',
          titleStepKO: '2단계',
          titleKO: '주문 처리',
          descriptionKO: '네가 완성한 주문서에 근거해서 우리는 운송방식과 비용을 확정할 것이다.',
          titleStepJP: 'ステップ2',
          titleJP: '注文処理',
          descriptionJP: '完了した注文に基づいて、配送方法と費用を決定します',
        },
        {
          titleStep: 'Step 3',
          title: 'Pick-up',
          description: 'Either send your inventory to our fulfillment centers or let us pick them up at the manufacturer',
          image: Step3,
          alt: 'shipkoo best ecommerce origin fulfillment service',
          titleStepZH: '第三步',
          titleZH: '取货',
          descriptionZH: '运送你的货物到我们的仓配中心，或者我们帮你从工厂取货',
          titleStepKO: '3단계',
          titleKO: '물품을 찾다',
          descriptionKO: '너의 화물을 우리의 창고로 운송하든지 아니면 우리가 너를 도와 공장에서 물건을 가져오든지 한다.',
          titleStepJP: 'ステップ3',
          titleJP: '選び出す',
          descriptionJP:'商品を倉庫配送センターに配送するか、工場から商品を受け取るお手伝いをします',
        },
        {
          titleStep: 'Step 4',
          title: 'Fulfillment',
          description: 'We prepare your orders and make them ready for shipment',
          image: Step4,
          alt: 'shippcoo ecommerce order fulfillment express shipping service',
          titleStepZH: '第四步',
          titleZH: '仓配',
          descriptionZH: '我们准备好你的订单和运输过程',
          titleStepKO: '4단계',
          titleKO: '창고에 배급하다',
          descriptionKO: '우리는 너의 주문서와 운송 과정을 잘 준비했다.',
          titleStepJP: 'ステップ4',
          titleJP: '倉庫流通',
          descriptionJP: 'ご注文と発送のプロセスを準備します',
        },
        {
          titleStep: 'Step 5',
          title: 'Delivery',
          description: 'All the orders are being shipped out to backers worldwide',
          image: Step5,
          alt: 'shipkoo ecommerce order fulfillment service product pickup',
          titleStepZH: '第五步',
          titleZH: '派送',
          descriptionZH: '所有订单会发往世界各地的赞助商',
          titleStepKO: '5단계',
          titleKO: '파송',
          descriptionKO: '모든 주문서는 세계 각지의 스폰서로 보내질 것이다.',
          titleStepJP: 'ステップ5',
          titleJP: '配信',
          descriptionJP: 'すべての注文は世界中のスポンサーに送信されます',
        },
      ]}
    />
  );
};

export default ServicesPage;
